import type { FC } from 'react';
import React, { useState } from 'react';
import NextLink from 'next/link';
import type { Bundle, Product } from '@wilm/shared-types/product/Product';
import { ProductTypeKey } from '@wilm/shared-types/product/Product';
import Button from 'components/commercetools-ui/atoms/button';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useCart } from 'frontastic';
import ArrowTileIcon from '../../../../icons/arrow-tile';
import WishlistButton from '../../wishlist/components/wishlist-button';
import Input from 'components/commercetools-ui/atoms/input';

interface TestProductTileProps {
    product: Product;
}

const TestProductTile: FC<TestProductTileProps> = ({ product }) => {
    const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

    const [selectedVariant, setSelectedVariant] = useState(product?.variants[0]);
    const [qty, setQty] = useState('1');
    const [loading, setLoading] = useState(false);

    const { umbracoFrontendHost: host, wishlist } = useBrandSettingsContext();

    const productUrl = host + product._url;
    const { addItem } = useCart();

    const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //check if value is a number
        const targetValue = e.target.value;
        const numberRegex = /^\d*$/;
        const isNumber = numberRegex.test(targetValue);
        if (targetValue === '' || isNumber) {
            setQty(targetValue);
        }
    };

    const handleAddToCart = async () => {
        setLoading(true);

        const bundle: Bundle = {};

        if (product.productTypeKey === ProductTypeKey.BUNDLE && !!selectedVariant.attributes?.administrateLearningPathId) {
            bundle.selectedCommencementDate = new Date().toISOString().split('T')[0];
        }

        if (product.productTypeKey === ProductTypeKey.BUNDLE && !!selectedVariant.attributes?.administrateLearningPathId) {
            const selectedBundleComponentVariantSKUs = selectedVariant.attributes?.bundleComponents.reduce(
                (acc: string[], component: { obj: { masterData: { current: { masterVariant: { sku: string } } } } }) => {
                    return [...acc, component.obj.masterData.current.masterVariant.sku];
                },
                []
            );

            bundle.selectedBundleComponentVariantSKUs = selectedBundleComponentVariantSKUs;
        }

        let qtyToAdd = parseInt(qty, 10);
        if (isNaN(qtyToAdd)) {
            qtyToAdd = 1;
        }

        await addItem(selectedVariant, qtyToAdd, bundle, product.productTypeKey);
        setLoading(false);
    };

    return (
        <div className="relative">
            <div className="relative min-h-220 w-full rounded-tile bg-tile-primary py-26 pl-20 pr-26 transition-colors  xl:min-h-300 xl:py-18 xl:pl-38 xl:pr-32">
                {product?.productTypeName && (
                    <div className="mb-15 flex items-center justify-between xl:mb-60">
                        <p className="font-tile text-15 font-medium leading-tight xl:text-20">
                            {formatProductMessage({
                                id: 'product.type.name',
                                defaultMessage: 'ICA {productTypeName}',
                                values: { productTypeName: product.productTypeName }
                            })}
                        </p>
                        {/* tooltip with list of selectedVariant attributes shown on hover  */}

                        <div className="group relative flex justify-end text-14">
                            <p className="w-50 justify-end bg-gray-100 p-6 text-center font-bold">Info</p>

                            <div className="absolute -left-130 top-25 hidden w-300 bg-gray-100 p-4 text-left text-14 group-hover:block">
                                <p className="font-bold">Payment methods:</p>
                                {selectedVariant.attributes?.supportedPaymentMethods.map(
                                    (paymentMethod: { key: string; label: string }) => (
                                        <div key={paymentMethod.key} className="mt-4">
                                            {paymentMethod.label}
                                        </div>
                                    )
                                )}
                                <p className="mt-10">
                                    <span className="font-bold">LA1Id: </span> {selectedVariant.attributes?.la1Id}{' '}
                                </p>
                            </div>
                        </div>
                        <NextLink href={productUrl}>
                            <div className="group relative size-44 flex-none rounded-full bg-tile-accent hover:bg-tile-primary xl:size-54">
                                <ArrowTileIcon className="absolute left-1/2 top-1/2 w-25  -translate-x-1/2 -translate-y-1/2  brightness-0  invert group-hover:filter-none lg:w-31" />
                            </div>
                        </NextLink>
                    </div>
                )}
                <div>
                    <div className="mb-16 font-heading">{product.key}</div>
                </div>
                <div>
                    <div className="font-heading">{selectedVariant?.attributes?.displayName ?? product.name}</div>
                </div>
                {product.variants.length > 1 ? (
                    product.variants.map(variant => (
                        <Button
                            key={variant.id}
                            className={`mt-10 rounded-sm bg-tile-primary px-8 py-6 text-14 font-medium text-primary-black shadow-button disabled:bg-tile-accent disabled:text-tile-primary`}
                            onClick={() => setSelectedVariant(variant)}
                            disabled={selectedVariant?.id === variant.id}
                        >
                            {variant.sku}
                        </Button>
                    ))
                ) : (
                    <div className="mt-10 text-14">{selectedVariant?.sku}</div>
                )}
                <Input className="mt-30" type="text" value={qty} onChange={handleQtyChange} />

                <Button
                    className="mt-8 w-full rounded-sm text-14 font-medium"
                    variant="primary"
                    onClick={handleAddToCart}
                    loading={loading}
                >
                    Add to cart
                    {!selectedVariant?.isOnStock && <span className="ml-6 mt-10 text-14 text-red-500">OOS</span>}
                </Button>
                {/* add to wishlist button */}
                {wishlist?.enabled && (
                    <WishlistButton
                        lineItem={{
                            lineItemId: selectedVariant?.id ?? '',
                            variant: {
                                sku: selectedVariant?.sku
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default TestProductTile;
